exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-campaign-conflict-minerals-campaign-beta-launch-js": () => import("./../../../src/pages/campaign/conflict-minerals-campaign-beta-launch.js" /* webpackChunkName: "component---src-pages-campaign-conflict-minerals-campaign-beta-launch-js" */),
  "component---src-pages-campaign-conflict-minerals-campaign-pre-launch-js": () => import("./../../../src/pages/campaign/conflict-minerals-campaign-pre-launch.js" /* webpackChunkName: "component---src-pages-campaign-conflict-minerals-campaign-pre-launch-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-ebook-js": () => import("./../../../src/pages/ebook.js" /* webpackChunkName: "component---src-pages-ebook-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletters-unsubscribe-js": () => import("./../../../src/pages/newsletters/unsubscribe.js" /* webpackChunkName: "component---src-pages-newsletters-unsubscribe-js" */),
  "component---src-pages-newsroom-js": () => import("./../../../src/pages/newsroom.js" /* webpackChunkName: "component---src-pages-newsroom-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-promotions-compliance-software-promotion-js": () => import("./../../../src/pages/promotions/compliance-software-promotion.js" /* webpackChunkName: "component---src-pages-promotions-compliance-software-promotion-js" */),
  "component---src-pages-promotions-conflict-minerals-js": () => import("./../../../src/pages/promotions/conflict-minerals.js" /* webpackChunkName: "component---src-pages-promotions-conflict-minerals-js" */),
  "component---src-pages-promotions-pfas-campaign-js": () => import("./../../../src/pages/promotions/PFAS-campaign.js" /* webpackChunkName: "component---src-pages-promotions-pfas-campaign-js" */),
  "component---src-pages-resources-webinar-product-compliance-js": () => import("./../../../src/pages/resources/webinar-product-compliance.js" /* webpackChunkName: "component---src-pages-resources-webinar-product-compliance-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-templates-blogs-js": () => import("./../../../src/templates/blogs.js" /* webpackChunkName: "component---src-templates-blogs-js" */),
  "component---src-templates-categories-js": () => import("./../../../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */),
  "component---src-templates-ebooks-js": () => import("./../../../src/templates/ebooks.js" /* webpackChunkName: "component---src-templates-ebooks-js" */),
  "component---src-templates-news-categories-js": () => import("./../../../src/templates/news-categories.js" /* webpackChunkName: "component---src-templates-news-categories-js" */),
  "component---src-templates-newsletters-js": () => import("./../../../src/templates/newsletters.js" /* webpackChunkName: "component---src-templates-newsletters-js" */),
  "component---src-templates-products-js": () => import("./../../../src/templates/products.js" /* webpackChunkName: "component---src-templates-products-js" */),
  "component---src-templates-reader-js": () => import("./../../../src/templates/reader.js" /* webpackChunkName: "component---src-templates-reader-js" */),
  "component---src-templates-services-js": () => import("./../../../src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */)
}

